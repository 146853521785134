
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
/*const firebaseConfig = {
  apiKey: "AIzaSyDoT5lV7aQadXEddKu_nQ026rpbngwaFUs",
  authDomain: "tabulare-bb269.firebaseapp.com",
  projectId: "tabulare-bb269",
  storageBucket: "tabulare-bb269.appspot.com",
  messagingSenderId: "1016623848181",
  appId: "1:1016623848181:web:49b8d317fb05ddc19c43bc"
};
*/

/*const firebaseConfig = {
  apiKey: "AIzaSyDoT5lV7aQadXEddKu_nQ026rpbngwaFUs",
  authDomain: "tabulare-bb269.firebaseapp.com",
  projectId: "tabulare-bb269",
  storageBucket: "tabulare-bb269.appspot.com",
  messagingSenderId: "1016623848181",
  appId: "1:1016623848181:web:49b8d317fb05ddc19c43bc"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyBVWTxEMVfkU5toSgM-vk4TiSNW2kH-3NQ",
  authDomain: "tabulare2-517e4.firebaseapp.com",
  projectId: "tabulare2-517e4",
  storageBucket: "tabulare2-517e4.appspot.com",
  messagingSenderId: "527441794045",
  appId: "1:527441794045:web:6fbd0a5e8a4d6881947f94",
  measurementId: "G-TNXX8FFFY8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
